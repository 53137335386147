import { LogLevel } from '@grafana/faro-react'
import { useEffect, useRef } from 'react'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import { ScoringRoles } from '../../providers/LiveScoringProvider/LiveScoringProvider.types'
import { pushLog } from '../../utils/logs'
import {
  GamePutPayload,
  GameToPublish,
} from '../GameDatastoreContext/GameDatastoreContext'
import {
  GameSyncingWorkerAddEventsMessage,
  GameSyncingWorkerChangeScoringRoleMessage,
  GameSyncingWorkerEventsSyncedNotification,
  GameSyncingWorkerGameResetEvent,
  GameSyncingWorkerGameUpdatedInPlaymakerEvent,
  GameSyncingWorkerGameUpdatedMessage,
  GameSyncingWorkerInitLiveScoringEvent,
  GameSyncingWorkerLiveGameConnectionState,
  GameSyncingWorkerLiveGameOwnerNotification,
  GameSyncingWorkerOutputMessage,
  GameSyncingWorkerPublishGameMessage,
  GameSyncingWorkerPublishedGamesUpdate,
  GameSyncingWorkerScoreGameMessage,
  GameSyncingWorkerSessionCleanupMessage,
  gameResetEvent,
  gameUpdatedInPlaymakerEvent,
  liveGameConnectionStateEvent,
  liveGameOwnerNotificationEvent,
  locallyScoredGameUpdateEvent,
  publishedGameUpdateEvent,
} from './GameSyncing.types'
import GameSyncingWorker from './GameSyncing.worker?worker'
import { IdbLocallyScoredGame } from './idb/Idb.types'

interface UseSyncingWorkerProps {
  //TODO: Can I generalise these?
  emitEvents: (
    payload: GameSyncingWorkerEventsSyncedNotification['payload'],
  ) => void
  emitLiveGameOwnerNotification?: (
    payload: GameSyncingWorkerLiveGameOwnerNotification['payload'],
  ) => void
  emitLocallyScoreGameUpdate?: (payload: IdbLocallyScoredGame) => void
  emitLiveGameConnectionState?: (
    payload: GameSyncingWorkerLiveGameConnectionState['payload'],
  ) => void
  emitPublishedGameUpdate: (
    p: GameSyncingWorkerPublishedGamesUpdate['payload'],
  ) => void
  emitPlaymakerGameUpdate: (
    p: GameSyncingWorkerGameUpdatedInPlaymakerEvent['payload'],
  ) => void
  emitGameResetNotification: (
    p: GameSyncingWorkerGameResetEvent['payload'],
  ) => void
}

export interface UseSyncingWorkerReturn {
  initWorker: (
    payload: GameSyncingWorkerInitLiveScoringEvent['payload'],
  ) => void
  sendEvent: (payload: GamePutPayload, onComplete?: () => void) => void
  scoreGame: (gameID: string) => void
  changeScoringRole: (gameID: string, role: ScoringRoles) => void
  publishGame: (game: GameToPublish) => void
  updatedGame: (gameID: string, date: Date) => void
  cleanupWorker: () => void
}

export const useSyncingWorker = ({
  emitEvents,
  emitLiveGameOwnerNotification,
  emitLocallyScoreGameUpdate,
  emitLiveGameConnectionState,
  emitPublishedGameUpdate,
  emitPlaymakerGameUpdate,
  emitGameResetNotification,
}: UseSyncingWorkerProps): UseSyncingWorkerReturn => {
  const debugLoggingEnabled = useFeatureFlagOn('bench-syncing-logging')

  const worker = useRef<Worker | undefined>(undefined)
  const state = useRef<'STARTING' | 'STARTED' | null>(null)

  const onMessage = (e: MessageEvent<GameSyncingWorkerOutputMessage>) => {
    switch (e.data.type) {
      case 'INITIALISED':
        state.current = 'STARTED'
        break
      case 'EVENTS_SYNCED':
        emitEvents(e.data.payload)
        break
      case liveGameConnectionStateEvent:
        emitLiveGameConnectionState?.(e.data.payload)
        break
      case liveGameOwnerNotificationEvent:
        emitLiveGameOwnerNotification?.(e.data.payload)
        break
      case locallyScoredGameUpdateEvent:
        emitLocallyScoreGameUpdate?.(e.data.payload)
        break
      case publishedGameUpdateEvent:
        emitPublishedGameUpdate(e.data.payload)
        break
      case gameUpdatedInPlaymakerEvent:
        emitPlaymakerGameUpdate(e.data.payload)
        break
      case gameResetEvent:
        emitGameResetNotification(e.data.payload)
        break
      case 'LOG':
        const message = e.data.message
        const options = e.data.options
        switch (options?.level) {
          case LogLevel.WARN:
            if (process.env.NODE_ENV === 'development' || debugLoggingEnabled) {
              console.warn(message, { ...options.context })
            }

            pushLog(e.data.message, e.data.options)
            break
          case LogLevel.ERROR:
            if (process.env.NODE_ENV === 'development' || debugLoggingEnabled) {
              console.error(message, { ...options.context })
            }

            pushLog(e.data.message, e.data.options)
            break
          case LogLevel.INFO:
          case LogLevel.LOG:
            if (process.env.NODE_ENV === 'development' || debugLoggingEnabled) {
              // eslint-disable-next-line no-console
              console.log(message, { ...options.context })
            }

            pushLog(e.data.message, e.data.options)
            break
          case LogLevel.DEBUG:
          case LogLevel.TRACE:
          default:
            if (process.env.NODE_ENV === 'development' || debugLoggingEnabled) {
              // eslint-disable-next-line no-console
              console.debug(message, { ...options?.context })
            }

            if (debugLoggingEnabled) {
              pushLog(e.data.message, e.data.options)
            }
            break
        }
    }
  }

  useEffect(() => {
    worker.current = new GameSyncingWorker()
    worker.current.addEventListener('message', onMessage)

    // Kill web worker on unmount
    return () => {
      if (worker) {
        worker.current?.terminate()
        worker.current?.removeEventListener('message', onMessage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    worker.current?.removeEventListener('message', onMessage)
    worker.current?.addEventListener('message', onMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debugLoggingEnabled])

  const initWorker = (
    payload: GameSyncingWorkerInitLiveScoringEvent['payload'],
  ) => {
    if (state.current) {
      return
    }

    if (!worker.current) {
      worker.current = new GameSyncingWorker()
      worker.current.addEventListener('message', onMessage)
    }

    const message: GameSyncingWorkerInitLiveScoringEvent = {
      type: 'INIT',
      payload,
    }
    state.current = 'STARTING'
    worker?.current?.postMessage(message)
  }

  const sendEvent = (payload: GamePutPayload, onComplete?: () => void) => {
    const message: GameSyncingWorkerAddEventsMessage = {
      type: 'ADD_EVENT',
      payload,
    }
    worker?.current?.postMessage(message)
    onComplete?.()
  }

  /**
   * Should be called when the user is navigating to a page to score a game. This will trigger a connection to check things like
   * 1. Are they the owner of the game
   * 2. Do they need to download an event log?
   */
  const scoreGame = (id: string) => {
    const message: GameSyncingWorkerScoreGameMessage = {
      type: 'SCORE_GAME',
      gameID: id,
    }
    worker?.current?.postMessage(message)
  }

  const publishGame = (game: GameToPublish) => {
    const message: GameSyncingWorkerPublishGameMessage = {
      type: 'PUBLISH_GAME',
      payload: game,
    }

    worker?.current?.postMessage(message)
  }

  const changeScoringRole = (gameID: string, role: ScoringRoles) => {
    const message: GameSyncingWorkerChangeScoringRoleMessage = {
      type: 'TAKEOVER_GAME',
      payload: {
        gameID,
        role,
      },
    }
    worker?.current?.postMessage(message)
  }

  const updatedGame = (id: string, date: Date) => {
    const message: GameSyncingWorkerGameUpdatedMessage = {
      type: 'UPDATED_GAME',
      payload: {
        gameID: id,
        date,
      },
    }
    worker?.current?.postMessage(message)
  }

  const cleanupWorker = () => {
    const message: GameSyncingWorkerSessionCleanupMessage = {
      type: 'SESSION_CLEANUP',
    }
    worker?.current?.postMessage(message)
    state.current = null
  }

  return {
    initWorker,
    sendEvent,
    scoreGame,
    publishGame,
    changeScoringRole,
    cleanupWorker,
    updatedGame,
  }
}
