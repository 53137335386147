import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react'
import { BrowserTransportOptions } from '@sentry/browser/build/npm/types/transports/types'
import * as Sentry from '@sentry/react'
import { useClient } from '@splitsoftware/splitio-react'
import posthog from 'posthog-js'
import React, { useEffect } from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { render } from 'react-dom'
import TagManager from 'react-gtm-module'
import { APP_VERSION } from './appVersion'
import AppProviders from './components/AppProviders'
import App from './pages/App'
import './i18n'
import { nonNullable } from './utils/array'
import { setIsWebView } from './utils/reactNativeWebView'

export const isProduction = window.location.href.includes('score.playhq.com')

if (process.env.REACT_APP_TENANT_SELECTOR === 'true') {
} else if (
  typeof window !== 'undefined' &&
  window.location.href.includes('localhost') === false
) {
  const href = window.location.href
  const environment = href.includes('dev.')
    ? 'dev'
    : href.includes('qa.')
    ? 'qa'
    : href.includes('uat.')
    ? 'uat'
    : href.includes('toblerones.')
    ? 'toblerones'
    : href.includes('thesharkeys.')
    ? 'thesharkeys'
    : href.includes('jatz.')
    ? 'jatz'
    : href.includes('koalas.')
    ? 'koalas'
    : href.includes('wizards.')
    ? 'wizards'
    : href.includes('perftest.')
    ? 'perftest'
    : href.includes('pentest.')
    ? 'pentest'
    : href.includes('sit.')
    ? 'sit'
    : 'production'

  if (isProduction) {
    TagManager.initialize({
      gtmId: 'GTM-K5FN4ZP',
    })
  } else {
    TagManager.initialize({
      gtmId: 'GTM-W762DQ43',
    })
  }

  const instrumentations = [...getWebInstrumentations()]

  if (process.env.REACT_APP_GRAFANA_FARO_TRACING_ENABLED === 'true') {
    // Dynamically import the tracing package if grafana tracing is enabled.
    // This is required as importing the package outside of this causes unit
    // tests in `InningsGame.test.tsx` to timeout and fail. Upgrading a major
    // version of `@testing-library/react` resolves the timeout issues, but
    // introduces flakiness in other tests (such as jest.fn() being called
    // multiple times).
    const faroWebTracing = require('@grafana/faro-web-tracing')

    const endpoints: string[] = [
      process.env.REACT_APP_TIP_OFF_WS_ENDPOINT,
      process.env.REACT_APP_SPECTATOR_ENDPOINT,
      process.env.REACT_APP_GRAPH_ENDPOINT,
    ].filter(nonNullable) as string[]

    instrumentations.push(
      new faroWebTracing.TracingInstrumentation({
        instrumentationOptions: {
          propagateTraceHeaderCorsUrls: endpoints,
        },
      }),
    )
  }

  if (process.env.REACT_APP_GRAFANA_FARO_URL) {
    initializeFaro({
      url: process.env.REACT_APP_GRAFANA_FARO_URL,
      app: {
        name: 'bench',
        version: APP_VERSION,
        environment,
      },
      instrumentations: instrumentations,
    })
  }

  const tenant = window.location.hostname.split('.')[0]
  const sentryEnvironment = href.includes('dev.')
    ? 'Dev'
    : href.includes('qa.')
    ? 'QA'
    : href.includes('uat.')
    ? 'UAT'
    : href.includes('toblerones.')
    ? 'Toblerones'
    : href.includes('thesharkeys.')
    ? 'Sharkeys'
    : href.includes('jatz.')
    ? 'Jatz'
    : href.includes('koalas.')
    ? 'Koalas'
    : href.includes('wizards.')
    ? 'Wizards'
    : href.includes('perftest.')
    ? 'Perftest'
    : href.includes('pentest.')
    ? 'Pentest'
    : href.includes('sit.')
    ? 'SIT'
    : 'Production'

  Sentry.init({
    dsn: 'https://29d762c08a35278fdbf6ac79102f5d6f@o297455.ingest.sentry.io/4508435662372864',
    environment: `${tenant.toUpperCase() ?? '-'} ${sentryEnvironment}`,
    release: APP_VERSION,
    integrations: [Sentry.browserProfilingIntegration()],
    sampleRate: isProduction ? 0.1 : 1,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
      maxQueueSize: 500,
    } as Partial<BrowserTransportOptions>,
    profilesSampleRate: isProduction ? 0.1 : 1,
  })
} else {
  if (process.env.REACT_APP_GRAFANA_FARO_URL) {
    initializeFaro({
      url: process.env.REACT_APP_GRAFANA_FARO_URL,
      app: {
        name: 'bench',
        version: APP_VERSION,
        environment: 'local',
      },
      instrumentations: getWebInstrumentations({
        captureConsole: false,
      }),
    })
  }
}

// Initialize PostHog
if (
  (process.env.REACT_APP_TENANT_SLUG === 'afl' ||
    process.env.REACT_APP_TENANT_SLUG === 'netball-australia') &&
  !window.location.host.includes('localhost') &&
  process.env.REACT_APP_POSTHOG_API_KEY
) {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_API_HOST,
    person_profiles: 'identified_only',
    autocapture: false,
    capture_pageview: false,
  })
}

setIsWebView()

const DisableSentry = () => {
  const client = useClient()

  useEffect(() => {
    client
      ?.ready()
      .then(() => {
        const treatment = client.getTreatment('bench-sentry')
        if (treatment !== 'on') {
          Sentry.close()
        }
      })
      .catch(e => {
        Sentry.captureException(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const bootstrap = (
  component: React.ReactElement<any>,
  elId = 'root',
) => {
  render(
    <React.StrictMode>
      <AppProviders>
        <DisableSentry />
        {component}
      </AppProviders>
    </React.StrictMode>,
    document.getElementById(elId),
  )
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCKS === 'true'
) {
  import('./mocks/dev-server').then(({ server }) =>
    server
      .start({ onUnhandledRequest: 'bypass' })
      .then(() => bootstrap(<App />)),
  )
} else if (process.env.NODE_ENV !== 'test') {
  bootstrap(<App />)
}
