import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import Loader from '@gameonsports/components/lib/Loader'
import { LocationProvider, navigate } from '@reach/router'
import * as Sentry from '@sentry/react'
import { persistCache } from 'apollo3-cache-persist'
import { OperationDefinitionNode } from 'graphql'
import localforage from 'localforage'
import { capitalize, merge } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import fragmentMatcher from '../../generated/graphql'
import typePolicies from '../../generated/graphql.typePolicies.json'
import theme from '../../theme'
import { encodeLocationParams } from '../../utils/location'
import { LogLevel, pushLog } from '../../utils/logs'
import AuthContext, {
  defaultAuth,
  getBearerToken,
  sessionAuth,
} from '../AuthContext'
import { ErrorBoundary } from '../ErrorBoundary'
import ErrorPage from '../ErrorPage/ErrorPage'
import FeatureFlagProvider from '../FeatureFlagProvider'

const getApolloClient = async () => {
  const tenant = window.location.hostname.split('.')[0]
  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      return defaultDataIdFromObject(responseObject)
    },
    possibleTypes: fragmentMatcher.possibleTypes,
    /**
     * Temp solution to resolve nullable fields we've added to the schema
     */
    typePolicies: {
      ...typePolicies,
      Game: merge(typePolicies.Game, {
        fields: {
          // DONT EVER REMOVE THIS IT WILL KILL YOU.
          // Cause we dont have the game-streaming infra deployed to tenants other than cricket it causes the fragment read to miss and then bench dies
          // If we ever deploy the infra to every env we can remove this
          liveStreamingEnabled: { read: (value?: any) => value ?? null },
        },
      }),
      GameType: merge(typePolicies.GameType, {
        fields: {
          // This can be removed along with feature flag `clock-counting-up` in ticket GO-26698
          clockType: { read: (value?: any) => value ?? null },
          compulsoryClosureOverLimit: { read: (value?: any) => value ?? null },
        },
      }),
    },
  })

  await persistCache({
    cache,
    storage: localforage,
    maxSize: false,
  }).catch(e => console.error(e))

  const authLink = setContext(async (_, context) => {
    try {
      const auth = context.auth || defaultAuth
      const session = await auth.currentSession()
      const accessToken = session.getIdToken()
      const token = accessToken.getJwtToken()

      return {
        headers: {
          ...context.headers,
          authorization: token && `Bearer ${token}`,
        },
      }
    } catch {
      return {
        headers: context.headers,
      }
    }
  })

  return new ApolloClient({
    link: from([
      authLink,
      onError(({ graphQLErrors, networkError, operation }) => {
        if (networkError) {
          Sentry.captureException(networkError)

          pushLog('Apollo network error', {
            level: LogLevel.ERROR,
            context: {
              errorName: networkError.name,
              errorMessage: networkError.message,
              ...(networkError.stack && { errorStack: networkError.stack }),
            },
          })
          networkError.message = 'There was a problem. Please try again.'
        }

        if (graphQLErrors) {
          const queryName =
            operation.operationName +
            capitalize(
              (
                operation.query.definitions.find(
                  d => d.kind === 'OperationDefinition',
                ) as OperationDefinitionNode | undefined
              )?.operation,
            )

          const err = new Error(JSON.stringify(graphQLErrors))
          err.name = `GraphQLError: ${queryName}`

          Sentry.captureException(err)

          const unauthError = graphQLErrors.find(
            error =>
              error.message === 'Unauthorised' ||
              !!(error.extensions && error.extensions.code === 'UNAUTHORISED'),
          )

          if (unauthError) {
            navigate('', {
              state: {
                unauthorised: true,
              },
            })
          }

          const insufficientMfaError = graphQLErrors.find(
            error => error.extensions?.code === 'INSUFFICIENT_MFA',
          )

          if (
            insufficientMfaError &&
            !window.location.href.includes('/security/add-mfa')
          ) {
            const requiredMfaType = insufficientMfaError.extensions
              ?.requiredMfa as unknown as string

            const params = encodeLocationParams({
              type: requiredMfaType,
              redirect: window.location.href,
            })

            navigate(`/security/add-mfa${params}`)
          }
        }
      }),
      split(
        operation => operation.getContext().endpoint === 'spectator',
        createHttpLink({
          fetch,
          uri: process.env.REACT_APP_SPECTATOR_ENDPOINT,
          credentials: 'same-origin',
          headers: {
            'X-PHQ-Tenant': tenant,
          },
        }),
        createHttpLink({ uri: process.env.REACT_APP_GRAPH_ENDPOINT }),
      ),
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy(lastFetchPolicy) {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return 'cache-first'
          }
          return lastFetchPolicy
        },
      },
    },
  })
}

interface StyledThemeProviderProps {
  children: ReactNode
}

const StyledThemeProvider = ({ children }: StyledThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

interface AppProvidersProps {
  children?: ReactNode
}

const AppProviders = ({ children }: AppProvidersProps) => {
  /**
   * The call to persistCache for apollo-cache-persist is a promise.
   * It is possible we were trying to pull stuff out of the cache before it was initialized, which caused
   * invariant errors. For example if you refreshed the page inside the game
   * it could cause the next fragment read to return null
   *
   * Now we return a loader till this operation is complete
   */
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >()

  useEffect(() => {
    async function init() {
      const client = await getApolloClient()
      setClient(client)
    }

    init().catch(console.error)
  }, [])

  if (!client) {
    return <Loader />
  }

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <AuthContext.Provider
        value={{
          defaultAuth,
          sessionAuth,
          getBearerToken,
        }}
      >
        <FeatureFlagProvider>
          <ApolloProvider client={client}>
            <StyledThemeProvider>
              <LocationProvider>{children}</LocationProvider>
            </StyledThemeProvider>
          </ApolloProvider>
        </FeatureFlagProvider>
      </AuthContext.Provider>
    </ErrorBoundary>
  )
}

export default AppProviders
